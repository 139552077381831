<template>
  <dialog
    ref="modal"
    class="consent"
    v-if="showConsent"
  >
    <p class="message">
      Продолжая использовать наш сайт, вы даете согласие на обработку файлов cookie, которые
      обеспечивают правильную работу сайта, и подтверждаете, что ваш возраст достиг 18 лет.
    </p>

    <div class="checkbox">
      <Checkbox
        name="consent"
        :value="consent"
        @change="handleCheckbox"
      >
        Я принимаю условия
        <router-link
          to="/privacy"
          target="_blank"
        >
          Политики Конфиденциальности
        </router-link>
      </Checkbox>
    </div>

    <div class="action">
      <Button
        variant="secondary"
        :disabled="!consent"
        @click="registerConsentAgree"
      >Подтверждаю</Button>
    </div>
  </dialog>
</template>

<script>
import dialogPolyfill from 'dialog-polyfill';

import Button from '@/components/Button.vue';
import Checkbox from '@/components/Checkbox.vue';

export default {
  name: 'CookieConsent',
  data() {
    return {
      consent: false,
    };
  },
  computed: {
    showConsent() {
      return !this.$store.state.user.consentAgreed;
    },
  },
  mounted() {
    dialogPolyfill.registerDialog(this.$refs.modal);

    if (this.showConsent) {
      this.$refs.modal.showModal();
    }
  },
  beforeDestroy() {
    this.$refs.modal.close();
  },
  methods: {
    handleCheckbox(value) {
      this.consent = value;
    },
    registerConsentAgree() {
      this.$store.commit('registerConsentAgree');
    },
  },
  components: {
    Button,
    Checkbox,
  },
};
</script>

<style lang="scss" scoped>
.consent {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;

  max-width: 20rem;
  width: 100%;
  height: auto;

  padding: 2rem 2rem 1.5rem;

  background-color: var(--color-white);
  border: 0;
  border-radius: 1.5rem;

  @media (min-width: 768px) {
    max-width: 24rem;
  }
}

.consent::backdrop {
  background-color: rgba(#000, 0.52);
}

.consent ~ .backdrop {
  background-color: rgba(#000, 0.52);
}

.message {
  margin-top: 0;
  margin-bottom: 0;

  font-size: var(--fs-text);

  text-align: left;
}

.checkbox {
  margin-top: 1rem;
}

.action {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}
</style>
