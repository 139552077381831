const RecaptchaMixin = {
  mounted() {
    if (!document.getElementById('vm-recaptcha-script')) {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js';
      script.async = true;
      script.id = 'vm-recaptcha-script';
      document.head.appendChild(script);
    }

    if (!document.getElementById('vm-recaptcha-container')) {
      const container = document.createElement('div');
      container.id = 'vm-recaptcha-container';
      container.classList.add('g-recaptcha', 'visually-hidden');
      container.dataset.sitekey = '6LecK5QbAAAAACStdD8fa9OCT_MSLAFJjHZaYKox';
      container.dataset.size = 'invisible';
      document.body.appendChild(container);
    }
  },
  methods: {
    executeRecaptcha() {
      return new Promise((resolve, reject) => {
        if (window.grecaptcha) {
          window.grecaptcha.ready(() => {
            window.grecaptcha
              .execute()
              .then(() => this.onRecaptchaSubmit())
              .then(() => resolve())
              .catch((e) => reject(e));
          });
        }
      });
    },
    onRecaptchaSubmit() {
      return new Promise((resolve, reject) => {
        const res = window.grecaptcha.getResponse();

        if (typeof res === 'string' && res.length !== 0) {
          resolve();
        } else {
          reject();
        }
      });
    },
  },
};

export default RecaptchaMixin;
