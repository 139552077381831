<template>
  <div :class="['control', hasLabel && 'control--with-label']">
    <label
      v-if="hasLabel"
      :for="id"
      :class="['label', required && 'label--required', error && 'label--error']"
    >
      <slot></slot>
    </label>

    <div class="wrapper">
      <input
        :id="id"
        :name="`${name}[]`"
        :class="['input', error && 'input--error']"
        v-model="from"
        type="number"
        :min="min"
        :max="to || 99"
        placeholder="от"
        :required="required"
        :disabled="disabled"
        @blur="handleBlurFrom"
      />
      <div :class="['divider', error && 'divider--error']"></div>
      <input
        :name="`${name}[]`"
        :class="['input', error && 'input--error']"
        type="number"
        v-model="to"
        :min="from"
        :max="max || 99"
        placeholder="до"
        :required="required"
        :disabled="disabled"
        @blur="handleBlurTo"
      />
    </div>
  </div>
</template>

<script>
import uniqueId from 'lodash/uniqueId';

export default {
  props: {
    name: String,
    value: Array,
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 99,
    },
    placeholder: String,
    required: Boolean,
    error: String,
    disabled: Boolean,
  },
  data() {
    return {
      id: uniqueId(),
      timeout: 0,
    };
  },
  computed: {
    hasLabel() {
      return !!this.$slots.default;
    },
    from: {
      set(val) {
        const cuttedValue = this.limitMinValue(val, 0);
        this.$emit('input', [cuttedValue, this.to]);
      },
      get() {
        return this.value[0];
      },
    },
    to: {
      set(val) {
        const cuttedValue = this.limitMaxValue(val, this.max);

        this.$emit('input', [this.from, cuttedValue]);
      },
      get() {
        return this.value[1];
      },
    },
  },
  watch: {
    from() {
      if (this.from === 0) {
        this.from = undefined;
      }
    },
    to() {
      if (this.to === 0) {
        this.to = undefined;
      }
    },
  },
  methods: {
    limitMaxValue(value, max) {
      return Math.min(value, max);
    },
    limitMinValue(value, min) {
      return Math.max(value, min);
    },
    handleBlurFrom() {
      if (+this.from > +this.to) {
        this.to = this.from;
        return;
      }

      const cuttedValue = this.limitMinValue(
        this.limitMaxValue(this.from, this.to || this.max),
        this.min,
      );

      this.$emit('input', [cuttedValue, this.to]);
    },
    handleBlurTo() {
      if (+this.to < +this.from) {
        this.from = this.to;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.control {
  width: 100%;

  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  align-items: center;

  &--with-label {
    grid-template-columns: 5.5rem 1fr;
  }

  @media (min-width: 1024px) {
    &--with-label {
      grid-template-columns: 6.5rem 1fr;
    }
  }
}

.label {
  color: var(--color-primary-50);
  line-height: 1.15;
  text-align: right;

  &--required:after {
    content: '*';
    color: var(--color-primary-50);
  }

  &--error {
    color: var(--color-danger-20);
  }

  @media (min-width: 1024px) {
    top: 1.25rem;
  }
}

.wrapper {
  display: flex;
  align-items: center;
}

.input {
  width: 4rem;
  padding: 0.375rem 0.5rem;

  font-size: var(--fs-text);
  line-height: 1.33;

  color: var(--color-text);

  border: 1px solid var(--color-gray-10);
  border-radius: 0.5rem;

  appearance: none;

  &--error {
    border-color: var(--color-danger-20);
  }

  &::placeholder {
    color: var(--color-gray-20);
  }

  @media (min-width: 1024px) {
    height: 2.5rem;
  }
}

.divider {
  width: 1rem;
  height: 1px;
  background-color: var(--color-gray-10);

  &--error {
    background-color: var(--color-danger-20);
  }
}
</style>
