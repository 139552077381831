<template>
  <router-view />
</template>

<script>
export default {
  metaInfo: {
    htmlAttrs: {
      lang: 'ru',
    },
    title: 'Найди друзей поблизости',
    titleTemplate: 'Radius Friend — %s',
    meta: [
      {
        name: 'description',
        content: 'Приложение для поиска друзей и единомышленников',
      },
      {
        name: 'msapplication-TileColor',
        content: '#ffffff',
      },
      {
        name: 'theme-color',
        content: '#ffffff',
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:title',
        content: 'Найди друзей поблизости',
        vmid: 'og:title',
        template: (chunk) => `Radius Friend — ${chunk}`,
      },
      {
        property: 'og:description',
        content: 'Приложение для поиска друзей и единомышленников',
        vmid: 'og:description',
      },
      {
        property: 'og:url',
        content: 'https://radiusfriend.com',
      },
      {
        property: 'og:image',
        content: '/preview.jpg',
      },
      {
        property: 'og:image:width',
        content: '1200',
      },
      {
        property: 'og:image:height',
        content: '630',
      },
      {
        name: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        name: 'twitter:site',
        content: 'Radius Friend',
      },
      {
        name: 'twitter:creator',
        content: '@zavsol',
      },
      {
        name: 'twitter:title',
        content: 'Найди друзей поблизости',
        template: (chunk) => `Radius Friend — ${chunk}`,
        vmid: 'twitter:title',
      },
      {
        name: 'twitter:description',
        content: 'Приложение для поиска друзей и единомышленников',
        vmid: 'twitter:description',
      },
      {
        name: 'twitter:image',
        content: '/preview.jpg',
      },
    ],
    link: [
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '/apple-touch-icon.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: '/favicon-32x32.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: '/favicon-16x16.png',
      },
      {
        rel: 'manifest',
        href: '/site.webmanifest',
      },
      {
        rel: 'mask-icon',
        href: '/safari-pinned-tab.svg',
        color: '#41ad49',
      },
    ],
  },
};
</script>

<style lang="scss">
#app {
  height: 100%;
  flex: 1 0 100%;
  display: flex;
  flex-direction: column;
}
</style>
