const IntersectionObserverMixin = {
  install(Vue, pluginOptions) {
    const options = {
      rootMargin: '0px',
      threshold: 1,
      ...pluginOptions,
    };
    let callbacks = [];

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const currentEntry = callbacks.find((cb) => cb.el === entry.target);

        if (currentEntry) {
          currentEntry.cb(entry);
        }
      });
    }, options);

    const subscribe = (el, cb) => {
      if (callbacks.includes(cb)) return;

      observer.observe(el);

      callbacks.push({
        el,
        cb,
      });
    };

    const unsubscribe = (el) => {
      const index = callbacks.findIndex((entry) => entry.el === el);

      if (index === -1) return;

      observer.unobserve(el);

      callbacks = [
        callbacks.slice(0, index),
        callbacks.slice(index),
      ];
    };

    Vue.prototype.$observer = {
      subscribe,
      unsubscribe,
    };
  },
};

export default IntersectionObserverMixin;
