/* eslint-disable no-underscore-dangle */
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import parse from 'date-fns/parse';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import unionBy from 'lodash/unionBy';
import isError from 'lodash/isError';

Vue.use(Vuex);

const initialState = {
  app: {
    stage: 'init',
    fetching: true,
  },
  map: {
    initialized: false,
    radius: 0,
    center: null,
    zoom: 14,
  },
  user: {
    token: null,
    firstVisit: true,
    consentAgreed: false,
  },
  events: {
    all: [],
    types: [],
    created: [],
    new: {
      type: {
        label: '',
        value: '',
      },
      address: '',
      latLng: [],
      date: '',
      time: '',
      ownerAge: null,
      targetAge: [null, null],
      participantCount: [null, null],
      comment: '',
      contact: '',
      silence: true,
      cost: null,
    },
    filter: {
      applied: false,
      dirty: false,
      type: {},
      date: [],
      // time: [],
      ownerAge: null,
      targetAge: [],
      participantCount: [],
      silence: true,
      free: null,
    },
    current: null,
  },
};

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    user: state.user,
    map: {
      radius: state.map.radius,
      center: state.map.center,
      zoom: state.map.zoom,
    },
    events: {
      new: state.events.new,
      created: state.events.created,
    },
  }),
});

export default new Vuex.Store({
  state: initialState,
  mutations: {
    initializeMap(state, value) {
      state.map.initialized = value;
    },
    updateMap(state, params) {
      state.map = {
        ...state.map,
        ...params,
      };
    },
    updateStage(state, newStage) {
      state.app.stage = newStage;
    },
    registerFirstVisit(state) {
      state.user.firstVisit = false;
    },
    registerConsentAgree(state) {
      state.user.consentAgreed = true;
    },
    receivedEvents(state, events) {
      state.events.all = events;
    },
    receivedEventData(state, event) {
      state.events.current = {
        ...state.events.current,
        ...event,
      };
    },
    receivedEventTypes(state, types) {
      state.events.types = types;
    },
    removedEvent(state, id) {
      const index = state.events.all.findIndex((event) => event.id === id);
      const indexAtCreated = state.events.created.findIndex((event) => event.id === id);

      if (index >= 0) {
        state.events.all = [
          ...state.events.all.slice(0, index),
          ...state.events.all.slice(index + 1),
        ];
      }

      if (indexAtCreated >= 0) {
        state.events.created = [
          ...state.events.created.slice(0, index),
          ...state.events.created.slice(index + 1),
        ];
      }
    },
    updateNewEvent(state, event) {
      state.events.new = {
        ...state.events.new,
        ...event,
      };
    },
    createdEvent(state, id) {
      const {
        type, date, time, cost, ...restParams
      } = state.events.new;

      const event = {
        id,
        name: type.label,
        dateTime: parse(`${date} ${time}`, 'yyyy-MM-dd HH:mm', new Date()).toISOString(),
        cost: Number(cost),
        ...restParams,
      };

      state.events.created = unionBy(state.events.created, [event], 'id');
    },
    resetNewEvent(state) {
      state.events.new = initialState.events.new;
    },
    updateFilter(state, event) {
      state.events.filter = {
        ...state.events.filter,
        ...event,
        dirty: true,
      };
    },
    resetFilter(state) {
      state.events.filter = {
        ...initialState.events.filter,
      };
    },
    loginUser(state, token) {
      state.user.token = token;
    },
    logoutUser(state) {
      state.user.token = null;
    },
  },
  actions: {
    async fetchEvents({ state, commit }, params) {
      state.app.fetching = true;

      const {
        applied, dirty, free, ...restFilterParams
      } = state.events.filter;

      const {
        center, radius,
      } = state.map;

      const fields = omit(state.events.filter, ['applied', 'dirty', 'radius', 'center', 'free']);
      const initialFields = omit(initialState.events.filter, ['applied', 'dirty', 'radius', 'center', 'free']);

      try {
        const res = await this._vm.$api.events.fetchEvents({
          filter:
            applied && !isEqual(fields, initialFields) && restFilterParams,
          center,
          radius,
          free,
          ...params,
        });

        console.log(res);

        commit('receivedEvents', res);
      } catch (e) {
        console.log(JSON.stringify(e, null, 2));

        commit('receivedEvents', []);
      }

      state.app.fetching = false;
    },
    async getEventById({ state, commit }, id) {
      const event = state.events.all.find((e) => id === e.id)
        || state.events.created.find((e) => id === e.id);

      if (event) {
        commit('receivedEventData', event);

        this._vm.$api.events.fetchEventById(id).then((res) => {
          if (isError(res)) return;

          commit('receivedEventData', { ...event, ...res });
        });

        return event;
      }

      const res = await this._vm.$api.events.fetchEventById(id);

      if (isError(res)) return null;

      await commit('receivedEventData', res);

      return res;
    },
    async removeEventById({ commit, state }, eventId) {
      const res = await this._vm.$api.events.removeEvent({ eventId, token: state.user.token });

      if (isError(res) && res.message === 'UNAUTHORIZED') {
        throw res;
      }

      if (isError(res)) {
        throw res;
      }

      return commit('removedEvent', eventId);
    },
    async fetchEventTypes({ commit }, namePart) {
      try {
        const res = await this._vm.$api.events.fetchEventTypes(namePart);

        commit('receivedEventTypes', res);
      } catch (e) {
        console.log(JSON.stringify(e, null, 2));

        commit('receivedEventTypes', []);
      }
    },
    async createEvent({ state, commit }) {
      const res = await this._vm.$api.events.createEvent(state.events.new, state.user.token);

      if (isError(res)) {
        throw new Error('Отсутствуют обязательные параметры');
      }

      commit('createdEvent', res);

      return res;
    },
    getLocationByIp() {
      return this._vm.$api.geolocation.getLocationByIp();
    },
    getLocationByLatLng(store, latLng) {
      return this._vm.$api.geolocation.getLocationByLatLng(latLng);
    },
    async loginUser({ commit }, { username, password }) {
      const token = await this._vm.$api.auth.loginUser({ username, password });

      if (token) {
        return commit('loginUser', token);
      }

      throw new Error('Неправильный логин или пароль');
    },
    async logoutUser({ commit, state }) {
      const success = await this._vm.$api.auth.logoutUser({ token: state.user.token });

      if (success) {
        return commit('logoutUser');
      }

      throw new Error('Сервер недоступен');
    },
  },
  modules: {},
  plugins: [vuexLocal.plugin],
});
