<template>
  <div :class="['container', className]">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Container',
  props: {
    className: String,
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding-left: 9vw;
  padding-right: 9vw;
  margin-left: auto;
  margin-right: auto;
  max-width: 67.5rem;
  width: 100%;

  @media (min-width: 1200px) {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
}
</style>
