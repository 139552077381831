<template>
  <svg class="icon">
    <use :href="'/sprite.svg#' + icon" />
  </svg>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    icon: String,
  },
};
</script>

<style lang="scss" scoped>
.icon {
  display: block;
  width: 1em;
  height: 1em;
}
</style>
