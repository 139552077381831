<template>
  <main class="home">
    <Container class="content">
      <div class="logo">
        <Logo />
      </div>
      <p class="message">
        Данный сайт предоставляет возможность быстро найти себе компаньона для любого вида занятия.
        Вы хотите найти человека для пешей прогулки, но у вас нет друзей? Или, может, у вас есть
        лишний билет в кино или театр, и вы ищете того, с кем хотите пойти? Тогда этот сайт для вас.
      </p>
      <Button
        class="button"
        to="/map"
      >Начать</Button>

      <picture class="picture">
        <source
          srcset="@/assets/images/home@2x.webp 2x, @/assets/images/home.webp"
          type="image/webp"
        />
        <img
          src="@/assets/images/home.png"
          alt="Фотография веселых людей на фоне заката"
          srcset="@/assets/images/home@2x.png 2x, @/assets/images/home.png"
          class="image"
        />
      </picture>
    </Container>

    <CookieConsent />

    <Footer />
  </main>
</template>

<script>
// @ is an alias to /src
import Logo from '@/components/Logo.vue';
import Button from '@/components/Button.vue';
import CookieConsent from '@/components/CookieConsent.vue';
import Container from '@/components/Container.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'Home',
  components: {
    Logo,
    Button,
    CookieConsent,
    Container,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.home {
  display: grid;
  grid-template-rows: 3fr auto;
  height: 100%;
  overflow-x: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  position: relative;
  z-index: 2;

  @media (min-width: 500px) {
    justify-content: center;
  }
}

.logo {
  font-size: 5vh;

  @media (min-width: 1024px) {
    font-size: 3rem;
  }

  @media (min-width: 1440px) {
    font-size: 3.8rem;
  }
}

.content {
  padding-top: 4vh;
  padding-bottom: 5vh;
}

.message {
  margin-top: 4vh;
  margin-bottom: 5vh;

  max-width: 17.75rem;

  @media (min-width: 1024px) {
    min-width: 26.125rem;
  }
}

.button {
  @media (min-width: 1024px) {
    min-width: 16.5rem;
  }
}

.image {
  position: absolute;
  left: 96%;
  top: 65%;

  width: 28vh;

  transform: translate(-50%, -50%);
  z-index: -1;

  @media (min-width: 375px) {
    left: 93%;
    top: 55vh;
  }

  @media (min-width: 410px) {
    width: 30vh;
  }

  @media (min-width: 500px) {
    top: 50%;
  }

  @media (min-width: 768px) {
    left: 53%;
    transform: translate(0, -50%);
  }

  @media (min-width: 1024px) {
    left: 60%;
    width: 40vh;
    max-width: 30rem;
  }
}
</style>
