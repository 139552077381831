<template>
  <ModalForm
    ref="modal"
    @submit="handleSubmit"
    @close="handleCloseModal"
  >
    <!-- Dummy Input used for preventing autofocus on Select control -->
    <input
      ref="dummyInput"
      class="visually-hidden"
    />
    <FormRow>
      <Select
        name="type"
        ref="type"
        :value="event.type"
        :options="eventTypes"
        placeholder="Введите новое событие или выберите из списка"
        :fetchOptions="handleOptionsLoad"
        @focus="handleOptionsLoad"
        @blur="$v.event.type.$touch"
        @input="handleUpdate('type', $event)"
        @option:selecting="handleUpdate('type', $event)"
        required
        combobox
        :error="$v.event.type.$error"
      >Тип занятия</Select>
    </FormRow>
    <!-- <FormRow>
          <AddressInput
            name="address"
            :value="event.address"
            @change="handleUpdate('address', $event)"
            placeholder="Город, улица, дом, стр"
          >Адрес</AddressInput>
        </FormRow> -->
    <FormRow>
      <Datepicker
        name="date"
        :value="event.date"
        placeholder="дд.мм.гггг"
        @change="handleUpdate('date', $event)"
        @blur="$v.event.date.$touch"
        required
        :error="$v.event.date.$error"
      >Дата</Datepicker>
    </FormRow>
    <FormRow>
      <Timepicker
        name="time"
        :value="event.time"
        placeholder="чч:мм"
        @change="handleUpdate('time', $event)"
        @blur="$v.event.time.$touch"
        required
        :error="$v.event.time.$error"
      >Время</Timepicker>
    </FormRow>
    <!-- <FormRow>
      <Input
        name="ownerAge"
        type="number"
        :value="event.ownerAge"
        @input="handleUpdate('ownerAge', $event)"
        @blur="$v.event.ownerAge.$touch"
        :min="0"
        :max="99"
        required
        :error="$v.event.ownerAge.$error"
      >Ваш возраст</Input>
    </FormRow> -->
    <FormRow>
      <Range
        name="targetAge"
        :value="event.targetAge"
        @input="handleUpdate('targetAge', $event)"
        :min="0"
        :max="99"
      >Возраст участников</Range>
    </FormRow>
    <FormRow>
      <Range
        name="participantCount"
        :value="event.participantCount"
        @input="handleUpdate('participantCount', $event)"
        :min="1"
        :max="99"
      >Кол-во участников</Range>
    </FormRow>
    <FormRow>
      <Input
        name="cost"
        :value="event.cost"
        @input="handleUpdate('cost', $event)"
        @blur="$v.event.cost.$touch"
        type="number"
        :min="0"
        placeholder="Бесплатно"
        :error="$v.event.cost.$error"
        large
      >Цена, ₽</Input>
    </FormRow>
    <FormRow>
      <Input
        name="comment"
        :value="event.comment"
        @input="handleUpdate('comment', $event)"
        @blur="$v.event.comment.$touch"
        type="textarea"
        placeholder="Расскажите о событии"
        :error="$v.event.comment.$error"
      >Комментарий</Input>
    </FormRow>
    <FormRow>
      <Input
        name="contact"
        :value="event.contact"
        @input="handleUpdate('contact', $event)"
        @blur="$v.event.contact.$touch"
        placeholder="Номер телефона или мессенджер"
        :error="$v.event.contact.$error"
        required
      >Контакт для связи</Input>
    </FormRow>
    <FormRow class="checkbox-field">
      <SilenceCheckbox
        name="silence"
        :value="event.silence"
        @change="handleUpdate('silence', $event)"
      />
    </FormRow>
    <FormRow>
      <Button
        class="create__reset"
        variant="ghost"
        type="reset"
        @click.prevent="handleReset"
      >Очистить форму</Button>

      <Button
        type="submit"
        class="create__submit"
        variant="secondary"
      >Отметить на карте</Button>
    </FormRow>
  </ModalForm>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, maxLength, minValue } from '@vuelidate/validators';
import { mapActions, mapMutations, mapState } from 'vuex';
import debounce from 'lodash/debounce';

import Input from '@/components/Input.vue';
import Datepicker from '@/components/Datepicker.vue';
import Timepicker from '@/components/Timepicker.vue';
import Range from '@/components/Range.vue';
import Button from '@/components/Button.vue';
import SilenceCheckbox from '@/components/SilenceCheckbox.vue';
// import AddressInput from '@/components/AddressInput.vue';
import Select from '@/components/Select.vue';
import ModalForm from '@/components/ModalForm.vue';
import FormRow from '@/components/FormRow.vue';

export default {
  name: 'Create',
  metaInfo: {
    title: 'Создание события',
    meta: [
      {
        vmid: 'og:title',
        property: 'og:title',
        content: 'Создание события',
      },
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: 'Создание события',
      },
    ],
  },
  setup() {
    return {
      $v: useVuelidate(),
    };
  },
  computed: {
    stage: {
      set(newStage) {
        this.$store.commit('updateStage', newStage);
      },
      get() {
        return this.$store.state.app.stage;
      },
    },
    ...mapState({
      event: (state) => state.events.new,
      eventTypes: (state) => state.events.types,
    }),
  },
  watch: {
    stage() {
      switch (this.stage) {
        case 'creating:form':
          this.$refs.modal.show();
          this.$refs.dummyInput.blur();
          this.$v.$reset();
          break;
        case 'creating:mark':
          this.resetFilter();
        // eslint-disable-next-line no-fallthrough
        default:
          this.$refs.modal.close();
      }
    },
  },
  validations() {
    return {
      event: {
        type: {
          label: {
            required,
            maxLength: maxLength(100),
          },
        },
        date: {
          required,
        },
        time: {
          required,
        },
        // ownerAge: {
        //   required,
        //   minValue: minValue(0),
        //   maxValue: maxValue(100),
        // },
        cost: {
          minValue: minValue(0),
        },
        comment: {
          maxLength: maxLength(500),
        },
        contact: {
          required,
          maxLength: maxLength(100),
        },
      },
    };
  },
  mounted() {
    this.updateStage('creating:form');
    this.$refs.modal.show();
    this.$refs.dummyInput.blur();

    const { query } = this.$route;

    if (query.type) {
      const type = query.type.slice(0, 500).replace(/[_/\\*#$%^]/, '');

      this.updateNewEvent({
        type: {
          label: type,
          value: type,
        },
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    this.updateStage('init');
    this.$refs.modal.close();
    next();
  },
  methods: {
    handleCloseModal() {
      this.updateStage('init');
      this.$router.push({ name: 'Map' });
    },
    handleUpdate(name, value) {
      this.updateNewEvent({
        [name]: value,
      });
    },
    handleOptionsLoad: debounce(function (query, setLoading) {
      let spinnerTimeout = 0;
      setLoading = setLoading || this.$refs.type.toggleLoading;

      spinnerTimeout = setTimeout(() => {
        setLoading(true);
      }, 1000);

      this.fetchEventTypes(query).then(() => {
        clearTimeout(spinnerTimeout);
        setLoading(false);
      });
    }, 200),
    async validateForm() {
      await this.$v.$validate();
    },
    handleSubmit() {
      this.$v.$touch();
      if (this.$v.$error) return;

      this.updateStage('creating:mark');
    },
    handleReset() {
      this.$v.$reset();
      this.resetNewEvent();
    },
    ...mapMutations(['updateNewEvent', 'resetNewEvent', 'updateStage', 'resetFilter']),
    ...mapActions(['fetchEventTypes']),
  },
  components: {
    Input,
    Datepicker,
    Timepicker,
    Range,
    Select,
    SilenceCheckbox,
    Button,
    ModalForm,
    FormRow,
    // AddressInput,
  },
};
</script>

<style lang="scss" scoped>
.create__reset {
  grid-row: 2 / 3;
  flex: 0 0 100%;
  order: 2;
}

.create__submit {
  flex: 0 0 100%;
}

.checkbox-field {
  padding-left: 4.25rem;
}

@media (min-width: 768px) {
  .create__reset {
    grid-row: 1 / 2;
    margin-top: 0;
    flex: 0 0 auto;
    order: -1;
  }

  .create__submit {
    flex: 1 0 auto;
  }

  .checkbox-field {
    padding-left: 5.25rem;
  }
}
</style>
