<template>
  <Checkbox
    :name="name"
    :value="value"
    @change="$emit('change', $event)"
    :tooltip="silenceTooltipConfig"
  >Не против неловкого молчания</Checkbox>
</template>

<script>
import Checkbox from '@/components/Checkbox.vue';

export default {
  name: 'SilenceCheckbox',
  props: {
    name: String,
    value: Boolean,
  },
  data() {
    return {
      silenceTooltipConfig: {
        content:
          'Все мы знаем, как бывает неловко в момент тишины с незнакомым человеком. Поэтому мы решили добавить данную опцию. Теперь вам не нужно волноваться о том, что вы не найдете сразу слов при общении, ведь ваш собеседник и вы будете не против неловкой тишины =)',
        placement: 'bottom-end',
        theme: 'primary',
        class: 'silence-tooltip',
      },
    };
  },
  components: {
    Checkbox,
  },
};
</script>

<style lang="scss">
.silence-tooltip .v-popper__inner {
  min-width: 16rem;
}
</style>
