<template>
  <div class="control">
    <label
      class="wrapper"
      @mouseenter="tooltipShown = true"
      @mouseleave="tooltipShown = false"
    >
      <input
        :name="name"
        type="checkbox"
        :class="['input', 'visually-hidden']"
        :checked="value"
        :required="required"
        :disabled="disabled"
        @focus="tooltipShown = true"
        @change="$emit('change', $event.target.checked)"
        @blur="handleBlur"
        v-tooltip="
          tooltip && {
            ...tooltip,
            placement: 'top-end',
            shown: tooltipShown,
            offset: [207, 5],
          }
        "
      />
      <span :class="['checkbox', error && 'checkbox--error']"></span>

      <span :class="['label', required && 'label--required', error && 'label--error']">
        <slot></slot>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    value: Boolean,
    required: Boolean,
    error: [String, Boolean],
    disabled: Boolean,
    tooltip: Object,
  },
  data() {
    return {
      tooltipShown: false,
    };
  },
  methods: {
    handleBlur($event) {
      this.tooltipShown = false;

      this.$emit('blur', $event);
    },
  },
};
</script>

<style lang="scss" scoped>
.control {
  position: relative;

  width: 100%;
}

.wrapper {
  display: flex;

  pointer-events: none;
}

.label {
  position: relative;
  display: inline-block;
  padding-top: 0.375rem;

  color: var(--color-primary-50);
  line-height: 1.15;

  pointer-events: all;

  justify-self: start;

  &::v-deep a {
    color: inherit;
  }

  &--required:after {
    content: '*';
    display: inline-block;
    width: 0;
    color: var(--color-primary-50);
  }

  &--error {
    color: var(--color-danger-20);
  }
}

.checkbox {
  position: relative;

  width: 1.75rem;
  height: 1.75rem;
  margin-right: 0.5rem;

  color: var(--color-primary-50);

  cursor: pointer;

  pointer-events: all;

  justify-self: end;

  &:before {
    content: '';

    position: absolute;
    top: 50%;
    left: 50%;

    display: block;
    width: 1.25rem;
    height: 1.25rem;

    border: 1px solid currentColor;
    border-radius: 0.375rem;

    transform: translate(-50%, -50%);
  }

  &:after {
    content: '';

    position: absolute;
    top: 50%;
    left: 50%;

    display: none;
    width: 0.75rem;
    height: 0.75rem;

    background-color: currentColor;

    border-radius: 50%;

    transform: translate(-50%, -50%);
  }

  &--error {
    border-color: var(--color-danger-20);
  }
}

.checkbox__tooltip {
  min-width: 20rem;
}

.input:checked ~ .checkbox:after {
  display: block;
}

.input:disabled ~ .checkbox,
.input:disabled ~ .label {
  color: var(--color-gray-10);
}
</style>
