// const isProduction = process.env.VUE_APP_ENV === 'production';
const isProduction = true;

export default {
  install: (app) => {
    const test = {
      api: {
        main: 'https://stage-back.radiusfriend.com/api',
      },
      geolocation: {
        dadata: 'ce9a6de8a103bf8cd314df8f3c5523be398dda75',
        geoip: '8beb310e4c7ddf6ddcef88d815bb72a4',
        geocoder: 'AIzaSyDLdonP3kt7_RnkhwfCpPhjfV0Sa_zqO7Y',
      },
    };

    const production = {
      api: {
        main: 'https://back.radiusfriend.com/api',
      },
      geolocation: {
        dadata: 'ce9a6de8a103bf8cd314df8f3c5523be398dda75',
        geoip: '8beb310e4c7ddf6ddcef88d815bb72a4',
        geocoder: 'AIzaSyDLdonP3kt7_RnkhwfCpPhjfV0Sa_zqO7Y',
      },
    };

    app.prototype.$config = Object.freeze(isProduction ? production : test);
  },
};
