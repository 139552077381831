<template>
  <div class="control">
    <label
      :for="id"
      :class="['label', required && 'label--required', error && 'label--error']"
    >
      <slot></slot>
    </label>

    <div class="control__container">
      <div class="control__wrapper">
        <input
          :id="id"
          :name="`${name}[]`"
          :class="['input', error && 'input--error', placeholderFromShown && 'input--empty']"
          type="date"
          v-model="from"
          :min="minValue"
          :max="range ? to : maxValue"
          :required="required"
          :disabled="disabled"
          autocomplete="off"
          @blur="handleBlur"
        />

        <span
          v-if="placeholderFromShown"
          class="control__placeholder"
        >
          {{ range ? 'от' : placeholder }}
        </span>
      </div>
      <div
        v-if="range"
        :class="['divider', error && 'divider--error']"
      ></div>
      <div
        v-if="range"
        class="control__wrapper"
      >
        <input
          :name="`${name}[]`"
          :class="['input', error && 'input--error', placeholderToShown && 'input--empty']"
          type="date"
          v-model="to"
          :min="from"
          :max="maxValue"
          :required="required"
          :disabled="disabled"
          autocomplete="off"
          @blur="handleBlur"
        />

        <span
          v-if="placeholderToShown"
          class="control__placeholder"
        >
          {{ range ? 'до' : placeholder }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import uniqueId from 'lodash/uniqueId';

export default {
  name: 'Datepicker',
  props: {
    name: String,
    max: Number,
    value: [String, Array],
    placeholder: String,
    required: Boolean,
    error: [String, Boolean],
    disabled: Boolean,
  },
  data() {
    return {
      id: uniqueId(),
      format: 'yyyy-MM-dd',
    };
  },
  computed: {
    from: {
      set(val) {
        const cuttedValue = this.limitMaxLength(val, this.maxValue);

        this.$emit('change', this.range ? [cuttedValue, this.to] : cuttedValue);
      },
      get() {
        return this.range ? this.value[0] : this.value;
      },
    },
    to: {
      set(val) {
        const cuttedValue = this.limitMaxLength(val, this.maxValue);

        this.$emit('change', this.range ? [this.from, cuttedValue] : cuttedValue);
      },
      get() {
        return this.range ? this.value[1] : this.value;
      },
    },
    placeholderFromShown() {
      if (this.range) {
        return !this.value[0];
      }

      return this.value === '' && this.placeholder;
    },
    placeholderToShown() {
      return !this.value[1];
    },
    range() {
      return Array.isArray(this.value);
    },
    minValue() {
      return format(new Date(), this.format);
    },
    maxValue() {
      return this.max || '2099-12-31';
    },
  },
  methods: {
    handleBlur() {
      const from = parse(this.from, this.format, new Date());
      const to = parse(this.to, this.format, new Date());

      const min = parse(this.minValue, this.format, new Date());
      const max = parse(this.maxValue, this.format, new Date());

      if (to > max) {
        this.to = this.maxValue;
      }

      if (from < min) {
        this.from = this.minValue;
      }

      if (to < from) {
        this.from = this.to;
      }

      if (from > to) {
        this.to = this.from;
      }

      this.$emit('blur');
    },
    limitMaxLength(value, max) {
      if (value.length > max.length) {
        return value.slice(0, max.length);
      }

      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.control {
  width: 100%;

  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 5.5rem 1fr;
  align-items: center;

  @media (min-width: 1024px) {
    grid-template-columns: 6.5rem 1fr;
  }
}

.control__container {
  display: flex;
  flex-direction: column;

  @media (min-width: 425px) {
    flex-direction: row;
    align-items: center;
  }
}

.divider {
  flex: 0 0 auto;
  width: 1rem;
  height: 0.5rem;

  @media (min-width: 425px) {
    height: 1px;
    background-color: var(--color-gray-10);

    &--error {
      background-color: var(--color-danger-20);
    }
  }
}

.control__wrapper {
  width: 8rem;
  position: relative;

  @media (min-width: 425px) {
    width: 0;
    flex: 1 1 0;

    &:only-child {
      width: 8rem;
      flex: 0 0 auto;
    }
  }
}

.control__placeholder {
  position: absolute;
  top: 0.5rem;
  left: 0.6rem;
  color: var(--color-gray-20);
  font-size: var(--fs-text);
  line-height: 1.33;
  pointer-events: none;

  @media (min-width: 1024px) {
    left: 0.65rem;
    top: 0.65rem;
  }
}

.label {
  position: relative;
  top: 1rem;
  color: var(--color-primary-50);
  line-height: 1.15;
  align-self: start;
  text-align: right;

  transform: translateY(-50%);

  &--required:after {
    content: '*';
    display: inline-block;
    width: 0;
    color: var(--color-primary-50);
  }

  &--error {
    color: var(--color-danger-20);
  }

  @media (min-width: 1024px) {
    top: 1.25rem;
  }
}

.input {
  width: 100%;
  height: 2rem;
  font-size: var(--fs-text);
  line-height: 1.33;
  padding: 0.375rem 0.5rem;
  padding-right: 0.25rem;
  letter-spacing: -0.03em;
  appearance: none;

  color: var(--color-text);
  background: var(--color-white);

  border: 1px solid var(--color-gray-10);
  border-radius: 0.5rem;

  &--empty {
    color: transparent;
  }

  &--error {
    border-color: var(--color-danger-20);
  }

  &:focus {
    color: inherit;

    user-select: none;
  }

  &:focus ~ .control__placeholder {
    display: none;
  }

  &::placeholder {
    color: var(--color-gray-20);
  }

  &::-webkit-calendar-picker-indicator {
    margin-left: 0.1rem;
  }

  @media (min-width: 1024px) {
    height: 2.5rem;
    padding: 0.5rem 0.25rem 0.5rem 0.5rem;
  }
}
</style>
