<template>
  <span class="spinner">
    <span class="spinner__icon"></span>
    <span :class="['spinner__label', !showText && 'visually-hidden']">
      <slot>Загрузка...</slot>
    </span>
  </span>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    showText: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  display: flex;
  align-items: center;
}

.spinner__icon {
  display: inline-block;
  align-self: center;
  width: 1.25em;
  height: 1.25em;
  border: 0.2em solid var(--color-primary-20);
  border-left-color: var(--color-primary-50);
  border-radius: 50%;

  transform: translateZ(0);
  -webkit-animation: vSelectSpinner 1.1s linear infinite;
  animation: vSelectSpinner 1.1s linear infinite;
  transition: opacity 0.1s;
}

.spinner__label {
  margin-left: 0.5rem;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
}
</style>
