<template>
  <ul class="socials">
    <li
      class="socials__item"
      v-for="item in services"
      :key="item.id"
    >
      <a
        class="socials__link"
        :href="item.url"
        :title="item.label"
        target="_blank"
        rel="noreferrer noopener"
      >
        <Icon :icon="item.id" />
      </a>
    </li>
  </ul>
</template>

<script>
import Icon from '@/components/Icon.vue';

export default {
  name: 'Socials',
  data() {
    return {
      services: [
        {
          id: 'youtube',
          label: 'YouTube',
          url: 'https://www.youtube.com/channel/UC70qNVJ-_K9f9xJNXx-aRYQ',
        },
        {
          id: 'vk',
          label: 'ВКонтакте',
          url: 'https://vk.com/radiusfriend',
        },
        {
          id: 'telegram',
          label: 'Telegram',
          url: 'https://t.me/Radiusfriend',
        },
        {
          id: 'instagram',
          label: 'Instagram',
          url: 'https://instagram.com/radiusfriend',
        },
        {
          id: 'mail',
          label: 'Электронная почта',
          url: 'mailto:zovladik@yandex.ru',
        },
      ],
    };
  },
  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
.socials {
  display: flex;
  justify-content: center;

  padding: 0;
  margin: 0;
  list-style: none;
}

.socials__item + .socials__item {
  margin-left: 1.5rem;
}

.socials__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.2rem;
  height: 2.2rem;
  color: var(--color-text);
  font-size: 1.8rem;
  border: 1px solid currentColor;
  border-radius: 50%;
}
</style>
