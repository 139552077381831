const urlRegex = /(https?:\/\/[^\s]+)/g;
const phoneRegex = /((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}/;

function urlify(text) {
  if (urlRegex.test(text)) {
    return text.replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`);
  }

  if (phoneRegex.test(text)) {
    const countryCode = (text.startsWith('8') || text.startsWith('+7')) ? '' : '+7';
    return text.replace(phoneRegex, (phone) => `<a href="tel:${countryCode}${phone.replace(/-\(\)\w/, '')}" target="_blank">${countryCode}${phone}</a>`);
  }

  return text;
}

export default urlify;
