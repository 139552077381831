import axios from 'axios';
import parse from 'date-fns/parse';
import get from 'lodash/get';

const events = ($axios) => ({
  async fetchEvents(params) {
    if (!params.center?.lat || !params.center?.lng) {
      throw new Error('Coordinates are required!');
    }

    const {
      filter, radius, center, free,
    } = params;

    let requestParams = {
      radius,
      centreLatitude: center.lat,
      centreLongitude: center.lng,
      paid: free === null ? null : !free,
    };

    if (filter) {
      const {
        type, participantCount, targetAge, date, time, ...restParams
      } = filter;

      const startDate = date[0] ? parse(date[0], 'yyyy-MM-dd', new Date()).toISOString() : '';
      const endDate = date[1] ? parse(date[1], 'yyyy-MM-dd', new Date()).toISOString() : '';

      requestParams = {
        startDateTime: startDate,
        endDateTime: endDate,
        minParticipantsCount: participantCount[0],
        maxParticipantsCount: participantCount[1] || participantCount[0],
        minParticipantAge: targetAge[0],
        maxParticipantAge: targetAge[1],
        typeId: type.id,
        name: type.value,
        ...restParams,
        ...requestParams,
      };
    }

    const res = await $axios.post('events/find', requestParams);

    return res.data.map(({
      contact,
      minParticipantsCount,
      maxParticipantsCount,
      minParticipantAge,
      maxParticipantAge,
      latitude,
      longitude,
      ...restData
    }) => ({
      contact,
      participantCount: [minParticipantsCount, maxParticipantsCount],
      targetAge: [minParticipantAge, maxParticipantAge],
      latLng: [latitude, longitude],
      ...restData,
    }));
  },
  async fetchEventById(id) {
    if (!id) {
      return new Error('Coordinates are required!');
    }

    try {
      const res = await $axios.get(`events/${id}`);

      const {
        contact,
        minParticipantsCount,
        maxParticipantsCount,
        minParticipantAge,
        maxParticipantAge,
        latitude,
        longitude,
        ...restData
      } = res.data;

      return ({
        contact,
        participantCount: [minParticipantsCount, maxParticipantsCount],
        targetAge: [minParticipantAge, maxParticipantAge],
        latLng: [latitude, longitude],
        ...restData,
      });
    } catch (e) {
      console.log(e);

      return new Error(e);
    }
  },
  async fetchEventTypes(namePart) {
    const res = await $axios.get('/eventTypes/typesLikeName', {
      params: {
        namePart,
      },
    });

    return res.data.map((item) => ({
      id: item.id,
      label: item.name,
      value: item.name,
    }));
  },
  async createEvent(params, token) {
    if (!params.latLng || !params.type || !params.date || !params.time) {
      return new Error('Required parameters are missing!');
    }

    const {
      type = {},
      latLng,
      participantCount,
      targetAge,
      date,
      time,
      cost,
      contact,
      ownerAge,
      ...restParams
    } = params;

    try {
      const res = await $axios.post('events/create', {
        token: token || undefined,
        dateTime: parse(`${date} ${time}`, 'yyyy-MM-dd HH:mm', new Date()).toISOString(),
        latitude: latLng[0],
        longitude: latLng[1],
        minParticipantsCount: participantCount[0],
        maxParticipantsCount: participantCount[1] || participantCount[0],
        minParticipantAge: targetAge[0],
        maxParticipantAge: targetAge[1],
        cost: Number(cost) || null,
        typeId: type.id,
        name: type.value,
        contact,
        ownerAge,
        ...restParams,
      });

      return res.data;
    } catch (e) {
      console.log(e);

      return new Error(e);
    }
  },
  async removeEvent({ eventId, token }) {
    if (!eventId) {
      return new Error('Required parameters are missing!');
    }

    try {
      const res = await $axios.post(`admin/delete/events/${eventId}`, {}, {
        headers: {
          'Content-Type': 'text/plain',
          token,
        },
      });

      return res.data;
    } catch (e) {
      if (e.response.status === 403) {
        return new Error('UNAUTHORIZED');
      }

      return new Error(e);
    }
  },
});

const geolocation = ($axios, $config) => ({
  async getLocationByIp() {
    try {
      const res = await $axios.get('https://ipapi.co/json/');

      return [res.data.latitude, res.data.longitude];
    } catch (e) {
      console.log(e);

      return new Error(e);
    }
  },
  async getLocationByLatLng({ lat, lng }) {
    try {
      const res = await $axios.get(
        'https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address',
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Token ${$config.geolocation.dadata}`,
          },
          params: {
            lat,
            lon: lng,
            count: 5,
          },
        },
      );

      return get(res, 'data.suggestions[0].value', '');
    } catch (e) {
      console.log(e);

      return '';
    }
  },
});

const auth = ($axios) => ({
  async loginUser({ username, password }) {
    try {
      const res = await $axios.post('login', { username, password });

      if (res.status === 200) {
        return res.data;
      }

      throw new Error('Неправильный логин или пароль');
    } catch (e) {
      return null;
    }
  },
  async logoutUser({ token }) {
    try {
      const res = await $axios.post('logout', {}, {
        headers: {
          token,
        },
      });

      if (res.status === 200) {
        return true;
      }

      throw new Error('Сервер недоступен');
    } catch (e) {
      console.log(JSON.stringify(e, null, 2));

      return new Error(e);
    }
  },
});

export default {
  install: (app) => {
    const instance = axios.create({
      baseURL: app.prototype.$config.api.main,
      timeout: 3000,
      headers: { 'Content-Type': 'application/json' },
    });

    const endpoints = {
      events: events(instance),
      geolocation: geolocation(instance, app.prototype.$config),
      auth: auth(instance),
    };

    app.prototype.$api = endpoints;
  },
};
