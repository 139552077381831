<template>
  <dialog
    class="overlay"
    ref="modal"
    @click.self="$emit('close', $event)"
  >
    <div class="modal">
      <form
        type="modal"
        class="form form--modal"
        @submit.prevent="$emit('submit', $event)"
        novalidate
      >
        <slot></slot>
      </form>
    </div>
  </dialog>
</template>

<script>
import dialogPolyfill from 'dialog-polyfill';

export default {
  name: 'ModalForm',
  methods: {
    show() {
      this.$refs.modal.show();
    },
    close() {
      this.$refs.modal.close();
    },
  },
  mounted() {
    dialogPolyfill.registerDialog(this.$refs.modal);
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;

  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;

  background: rgba(#000, 0.6);

  @media (min-width: 600px) {
    overflow: auto;
  }
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  min-height: 19rem;
  padding: 1rem 1rem 2rem;

  background: var(--color-white);
  border: 0;
  overflow: auto;

  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25);

  @media (min-width: 600px) {
    top: 1%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translateX(-50%);

    padding: 1.5rem 1.5rem 2rem;

    max-width: 25.5rem;
    border-radius: 1.5rem;
  }

  @media (min-width: 1024px) {
    max-width: 28rem;
  }
}

.close {
  position: absolute;
  top: 1.25rem;
  right: 0.25rem;

  padding: 0.5rem;

  color: var(--color-gray-10);
  font-size: 2.3rem;

  border: 0;
  background: none;
  box-shadow: none;

  cursor: pointer;
}

.form {
  display: flex;
  flex-direction: column;
}

.form--modal {
  padding-top: 6rem;
}
</style>
