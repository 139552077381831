<template>
  <div :class="['form__row', condensed && 'form__row--condensed']">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FormRow',
  props: {
    condensed: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.form__row {
  display: grid;
  grid-gap: 1.5rem;
  grid-auto-flow: column;
  align-items: center;

  & + #{&} {
    margin-top: 1rem;
  }

  & + #{&}--condensed {
    margin-top: 0.5rem;
  }

  &:last-child {
    padding-top: 1rem;
    flex-wrap: wrap;
  }

  @media (min-width: 1024px) {
    & + #{&} {
      margin-top: 1.25rem;
    }

    & + #{&}--condensed {
      margin-top: 1rem;
    }
  }
}
</style>
