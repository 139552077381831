<template>
  <div :class="['control', large && 'control--large']">
    <label
      v-if="hasLabel"
      :for="id"
      :class="['label', required && 'label--required', error && 'label--error']"
    >
      <slot></slot>
    </label>

    <div class="control__wrapper">
      <textarea
        v-if="type === 'textarea'"
        :id="id"
        :name="name"
        :class="['input', error && 'input--error', 'input--textarea']"
        :type="type"
        :value="value"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :required="required"
        :disabled="disabled"
        rows="3"
        autocomplete="off"
        @input="$emit('input', $event.target.value)"
        @blur="$emit('blur', $event)"
      />

      <input
        v-else
        :id="id"
        :name="name"
        :class="['input', error && 'input--error', `input--${type}`]"
        :type="type"
        :value="value"
        :min="minValue"
        :max="maxValue"
        :maxlength="maxlength"
        :pattern="pattern"
        :placeholder="placeholder"
        :required="required"
        :disabled="disabled"
        autocomplete="off"
        @input="handleInput(type, $event)"
        @blur="$emit('blur', $event)"
      />

      <span
        v-if="placeholderShown"
        class="control__placeholder"
      >
        {{ placeholder }}
      </span>
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format';
import uniqueId from 'lodash/uniqueId';

export default {
  props: {
    name: String,
    type: {
      validator(value) {
        return ['text', 'password', 'textarea', 'number', 'time', 'date', 'url', 'email'].indexOf(value) !== -1;
      },
      default: 'text',
    },
    min: Number,
    max: Number,
    maxlength: Number,
    value: [String, Number],
    placeholder: String,
    required: Boolean,
    pattern: RegExp,
    large: Boolean,
    error: [String, Boolean],
    disabled: Boolean,
  },
  data() {
    return {
      id: uniqueId(),
    };
  },
  computed: {
    placeholderShown() {
      return (
        this.value === '' && this.placeholder && (this.type === 'date' || this.type === 'time')
      );
    },
    minValue() {
      return this.type === 'date' ? format(new Date(), 'yyyy-MM-dd') : this.min;
    },
    maxValue() {
      return this.type === 'date' ? '2099-12-31' : this.max;
    },
    hasLabel() {
      return !!this.$slots.default;
    },
  },
  methods: {
    handleInput(type, $event) {
      if (type === 'date') {
        const cuttedValue = this.limitMaxLength($event.target.value, $event.target.max);

        $event.target.value = cuttedValue;
        this.$emit('input', cuttedValue);
        return;
      }

      if (type === 'number') {
        let cuttedValue = $event.target.value.replace(/\D/g, '');

        if (+cuttedValue === 0) {
          cuttedValue = '';
        }

        if (cuttedValue === '') {
          $event.target.value = cuttedValue;

          this.$emit('input', cuttedValue);
          return;
        }

        if (this.min) {
          cuttedValue = this.limitMinValue(cuttedValue, this.min);
        }

        if (this.max) {
          cuttedValue = this.limitMaxValue(cuttedValue, this.max);
        }

        $event.target.value = cuttedValue;

        this.$emit('input', cuttedValue);
        return;
      }

      this.$emit('input', $event.target.value);
    },
    limitMaxLength(value, max) {
      if (value.length > max.length) {
        return value.slice(0, max.length);
      }

      return value;
    },
    limitMaxValue(value, max) {
      return Math.min(value, max);
    },
    limitMinValue(value, min) {
      return Math.max(value, min);
    },
  },
};
</script>

<style lang="scss" scoped>
.control {
  width: 100%;

  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 5.5rem 1fr;
  align-items: center;

  @media (min-width: 1024px) {
    grid-template-columns: 6.5rem 1fr;
  }
}

.control__wrapper {
  grid-column: 2/ 3;
  position: relative;
}

.control__placeholder {
  position: absolute;
  top: 0.5rem;
  left: 0.6rem;
  color: var(--color-gray-20);
  font-size: var(--fs-text);
  line-height: 1.33;
  pointer-events: none;
}

.label {
  position: relative;
  top: 1rem;
  color: var(--color-primary-50);
  line-height: 1.15;
  align-self: start;
  text-align: right;

  transform: translateY(-50%);

  &--required:after {
    content: '*';
    display: inline-block;
    width: 0;
    color: var(--color-primary-50);
  }

  &--error {
    color: var(--color-danger-20);
  }

  @media (min-width: 1024px) {
    top: 1.25rem;
  }
}

.input {
  width: 100%;
  height: 2rem;
  font-size: var(--fs-text);
  line-height: 1.33;
  padding: 0.375rem 0.5rem;

  color: var(--color-text);

  border: 1px solid var(--color-gray-10);
  border-radius: 0.5rem;

  appearance: none;

  &--empty {
    color: transparent;
  }

  &--error {
    border-color: var(--color-danger-20);
  }

  &--textarea {
    height: auto;
    resize: none;
  }

  &--number {
    width: 4rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    justify-self: flex-start;

    .control--large & {
      width: 8rem;
    }
  }

  &--date,
  &--time {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &--date {
    width: 7.5rem;

    &::-webkit-calendar-picker-indicator {
      margin-left: 0.1rem;
    }
  }

  &--time {
    width: 5.5rem;
  }

  &:focus {
    color: inherit;
  }

  &:focus ~ .control__placeholder {
    display: none;
  }

  &::placeholder {
    color: var(--color-gray-20);
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  @media (min-width: 1024px) {
    height: 2.5rem;
    padding: 0.5rem;

    &--textarea {
      height: auto;
    }
  }
}
</style>
