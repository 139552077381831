var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'control',
    _vm.hasLabel && 'control--with-label',
    _vm.hasNoOptionsSlot && 'control--with-no-options' ]},[(_vm.hasLabel)?_c('label',{class:['label', _vm.required && 'label--required', _vm.error && 'label--error'],attrs:{"for":_vm.id}},[_vm._t("default")],2):_vm._e(),_c('VSelect',{ref:"input",class:['input', !_vm.value.value && 'input--empty', _vm.error && 'input--error'],attrs:{"inputId":_vm.id,"value":_vm.value,"placeholder":_vm.placeholder,"reduce":_vm.reduce,"options":_vm.options,"maxlength":_vm.maxlength,"disabled":_vm.disabled,"clearable":false,"taggable":_vm.combobox,"autofocus":false,"create-option":function (label) { return ({ id: null, label: label, value: label }); },"getOptionLabel":_vm.getOptionLabel},on:{"search":_vm.fetchOptions,"search:focus":function($event){return _vm.$emit('focus', $event)},"search:blur":function($event){return _vm.$emit('blur', $event)},"option:selecting":function($event){return _vm.$emit('option:selecting', $event)},"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
  var id = ref.id;
  var value = ref.value;
return [_c('div',{staticClass:"option"},[_c('span',{staticClass:"option__value"},[_vm._v(" "+_vm._s(value)+" ")]),(!id)?_c('span',{staticClass:"option__caption"},[_vm._v("Добавить")]):_vm._e()])]}},{key:"open-indicator",fn:function(ref){
  var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('Icon',{attrs:{"icon":"arrow-down"}})],1)]}},(_vm.hasNoOptionsSlot)?{key:"no-options",fn:function(ref){
  var search = ref.search;
  var searching = ref.searching;
return [_vm._t("no-options",null,{"search":search,"searching":searching})]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }