<template>
  <dialog
    class="modal"
    ref="modal"
  >
    <button
      type="button"
      class="close"
      @click="handleCloseModal"
    >
      <Icon icon="cross" />
    </button>

    <button
      v-if="isLoggedIn"
      type="button"
      class="remove"
      @click="handleRemoveEvent"
      title="Удалить событие"
    >
      <Icon icon="remove" />
    </button>

    <div
      v-if="status === 'loading'"
      class="content"
    >
      <p class="placeholder placeholder--heading"></p>

      <div class="row row--placeholder">
        <p class="placeholder"></p>
        <p class="placeholder placeholder--lg"></p>
      </div>

      <div class="row row--placeholder">
        <p class="placeholder placeholder--lg"></p>
        <p class="placeholder placeholder--md"></p>
      </div>

      <div class="row row--placeholder">
        <p class="placeholder"></p>
        <p class="placeholder placeholder"></p>
      </div>

      <div class="row row--placeholder">
        <p class="placeholder placeholder--sm"></p>
        <p class="placeholder placeholder--lg"></p>
      </div>

      <div class="row row--placeholder">
        <p class="placeholder placeholder--xl"></p>
      </div>
    </div>

    <div
      v-else-if="status === 'error'"
      class="content content--error"
    >
      <svg
        class="content__picture"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 167 167"
      >
        <mask
          id="a"
          maskUnits="userSpaceOnUse"
          x="5.752"
          y="5.753"
          width="159"
          height="159"
          fill="#000"
        >
          <path
            fill="#fff"
            d="M5.752 5.753h159v159h-159z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.752 73.017c0 34.485 27.78 62.265 62.264 62.265 13.705 0 26.35-4.388 36.614-11.84 2.291-1.663 5.482-1.562 7.484.44l35.127 35.126a4.789 4.789 0 006.772-6.772l-35.127-35.127c-2.002-2.002-2.103-5.193-.44-7.484 7.449-10.263 11.835-22.906 11.835-36.608 0-34.485-27.78-62.264-62.265-62.264s-62.264 27.78-62.264 62.264zm9.58 0a52.533 52.533 0 0152.684-52.685 52.533 52.533 0 0152.685 52.685 52.532 52.532 0 01-52.685 52.685 52.533 52.533 0 01-52.685-52.685z"
          />
        </mask>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.752 73.017c0 34.485 27.78 62.265 62.264 62.265 13.705 0 26.35-4.388 36.614-11.84 2.291-1.663 5.482-1.562 7.484.44l35.127 35.126a4.789 4.789 0 006.772-6.772l-35.127-35.127c-2.002-2.002-2.103-5.193-.44-7.484 7.449-10.263 11.835-22.906 11.835-36.608 0-34.485-27.78-62.264-62.265-62.264s-62.264 27.78-62.264 62.264zm9.58 0a52.533 52.533 0 0152.684-52.685 52.533 52.533 0 0152.685 52.685 52.532 52.532 0 01-52.685 52.685 52.533 52.533 0 01-52.685-52.685z"
          fill="#fff"
        />
        <path
          d="M159.013 152.236l3.035-3.035-3.035 3.035zm-6.772 6.772l3.034-3.034-3.034 3.034zm-42.611-35.566l2.521 3.473-2.521-3.473zm7.484.44l3.035-3.035-3.035 3.035zm-44.098 7.108c-32.114 0-57.972-25.858-57.972-57.973H6.46c0 36.855 29.701 66.556 66.556 66.556v-8.583zm34.093-11.02c-9.552 6.934-21.32 11.02-34.093 11.02v8.583c14.636 0 28.159-4.689 39.135-12.658l-5.042-6.945zm48.166 36.004l-35.126-35.127-6.069 6.07 35.126 35.126 6.069-6.069zm-34.424-35.83l35.127 35.127 6.07-6.07-35.127-35.127-6.07 6.07zm10.138-47.127c0 12.771-4.084 24.537-11.016 34.087l6.946 5.042c7.966-10.975 12.653-24.496 12.653-39.129h-8.583zM73.016 15.045c32.115 0 57.973 25.858 57.973 57.972h8.583c0-36.855-29.701-66.556-66.556-66.556v8.584zM15.044 73.017c0-32.114 25.858-57.972 57.972-57.972V6.46C36.161 6.461 6.46 36.162 6.46 73.017h8.584zM73.016 16.04A56.825 56.825 0 0016.04 73.017h8.584a48.242 48.242 0 0148.393-48.393V16.04zm56.977 56.977A56.825 56.825 0 0073.016 16.04v8.584a48.242 48.242 0 0148.394 48.393h8.583zm-56.977 56.977a56.825 56.825 0 0056.977-56.977h-8.583a48.242 48.242 0 01-48.394 48.394v8.583zM16.04 73.017a56.825 56.825 0 0056.977 56.977v-8.583a48.242 48.242 0 01-48.393-48.394h-8.584zm110.882 41.057c-.363-.362-.551-1.171-.002-1.928l-6.946-5.042c-2.777 3.825-2.763 9.399.878 13.04l6.07-6.07zm35.127 47.969a9.081 9.081 0 000-12.842l-6.07 6.07a.497.497 0 010 .703l6.07 6.069zm-12.842 0a9.081 9.081 0 0012.842 0l-6.07-6.069a.497.497 0 01-.703 0l-6.069 6.069zm-37.055-35.128c.757-.549 1.566-.361 1.929.002l6.069-6.07c-3.641-3.641-9.216-3.654-13.04-.877l5.042 6.945z"
          fill="#333"
          mask="url(#a)"
        />
        <path
          d="M86.566 63.388c0 2.309-.71 4.565-2.038 6.485a12.014 12.014 0 01-5.43 4.298 12.507 12.507 0 01-6.989.665 12.24 12.24 0 01-6.193-3.195 11.55 11.55 0 01-3.31-5.976 11.29 11.29 0 01.687-6.743 11.772 11.772 0 014.456-5.238 12.413 12.413 0 016.72-1.968c3.209 0 6.286 1.23 8.554 3.419 2.269 2.189 3.543 5.158 3.543 8.253zm12.097 0c0-6.174-2.542-12.095-7.067-16.461-4.525-4.366-10.662-6.819-17.06-6.819-6.4 0-12.537 2.453-17.061 6.819-4.525 4.366-7.067 10.287-7.067 16.461-.022 12.86 24.061 41.955 24.061 41.955s24.194-29.095 24.194-41.955z"
          fill="red"
        />
      </svg>
      <h3 class="heading heading--error">Событие не найдено</h3>
      <p class="paragraph">Возможно оно уже состоялось или было удалено.</p>

      <Button
        type="button"
        @click="handleCloseModal"
      >
        Закрыть
      </Button>
    </div>

    <div
      v-else
      class="content"
    >
      <h3 class="heading">{{ event.name }}</h3>

      <div class="row">
        <div class="label">Дата и время</div>
        <div class="value">{{ event.date }}, {{ event.time }}</div>
      </div>

      <div
        v-if="event.address"
        class="row"
      >
        <div class="label">Адрес</div>
        <div
          v-if="event.address"
          class="value"
        >{{ event.address }}</div>
      </div>

      <div
        v-if="event.age"
        class="row"
      >
        <div class="label">Возраст</div>
        <div
          v-if="event.age"
          class="value"
        >{{ event.age }}</div>
      </div>

      <div class="row">
        <div class="label">Кол-во участников</div>
        <div class="value">{{ event.participants }}</div>
      </div>

      <div
        v-if="event.cost"
        class="row"
      >
        <div class="label">Цена</div>
        <div class="value">{{ event.cost }} руб.</div>
      </div>

      <div
        v-if="event.comment"
        class="row"
      >
        <div
          class="comment"
          v-html="event.comment"
        ></div>
      </div>

      <div
        v-if="event.contact"
        class="row"
      >
        <Button
          v-if="!status"
          class="button"
          @click="handleContactShow"
        >Показать контакт</Button>

        <div
          v-if="status === 'contact-shown'"
          class="contact"
          v-html="event.contact"
        ></div>

        <div
          v-if="status === 'recaptcha-checking'"
          class="contact"
        >
          <Spinner showText />
        </div>

        <div
          v-if="status === 'recaptcha-error'"
          class="contact contact--error"
        >
          Вы не прошли проверку Recaptcha.<br />
          Контакт организатора будет скрыт для вас.
        </div>
      </div>
    </div>
  </dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { isThisYear, format, parseISO } from 'date-fns';

import RecaptchaMixin from '@/mixins/RecaptchaMixin';

import Icon from '@/components/Icon.vue';
import Button from '@/components/Button.vue';
import Spinner from '@/components/Spinner.vue';

import urlify from '@/helpers/urlify';

import 'dialog-polyfill/dist/dialog-polyfill.css';

export default {
  name: 'EventDetails',
  metaInfo() {
    return {
      title: this.loading ? 'Загрузка события...' : this.event.name,
      meta: [
        {
          name: 'description',
          content: this.loading
            ? 'Загрузка события...'
            : this.event.comment || 'Информация о событии',
        },
        ...(this.loading
          ? [
            {
              vmid: 'og:title',
              property: 'og:title',
              content: this.event.name,
            },
            {
              vmid: 'twitter:title',
              property: 'twitter:title',
              content: this.event.name,
            },
            {
              vmid: 'og:description',
              property: 'og:description',
              content: this.event.comment || 'Информация о событии',
            },
            {
              vmid: 'twitter:description',
              property: 'twitter:description',
              content: this.event.comment || 'Информация о событии',
            },
          ]
          : []),
      ],
    };
  },
  data() {
    return {
      status: 'loading',
      showContact: false,
      recaptchaError: false,
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => Boolean(state.user.token),
      event: (state) => {
        const event = state.events.current;

        if (!event) return {};

        const date = parseISO(event.dateTime);

        const formattedDate = date.toLocaleDateString(['ru-RU'], {
          day: 'numeric',
          month: 'long',
          year: isThisYear(date) ? undefined : 'numeric',
        });

        let age = '';
        const [targetAgeFrom, targetAgeTo] = event.targetAge;
        if (targetAgeFrom !== targetAgeTo) {
          const ageFrom = targetAgeFrom ? `от ${targetAgeFrom} ` : '';
          const ageTo = targetAgeTo ? `до ${targetAgeTo}` : '';
          age = `${ageFrom}${ageTo}`;
        } else if (targetAgeFrom) {
          age = `Только ${targetAgeFrom}`;
        } else {
          age = '';
        }

        let participants = '';
        const [fromParticipantCount, toParticipantCount] = event.participantCount;

        if (fromParticipantCount !== toParticipantCount) {
          const participantsTo = fromParticipantCount ? `от ${fromParticipantCount} ` : '';
          const participantsFrom = toParticipantCount ? `до ${toParticipantCount}` : '';

          participants = `${participantsTo}${participantsFrom}`;
        } else if (fromParticipantCount) {
          participants = fromParticipantCount;
        } else {
          participants = 'Не ограничено';
        }

        console.log(event.comment);

        return {
          ...event,
          contact: urlify(event.contact),
          date: formattedDate,
          time: format(date, 'HH:mm'),
          age,
          participants,
        };
      },
    }),
  },
  mounted() {
    this.$refs.modal.show();
  },
  beforeUnmount() {
    this.$refs.modal.close();
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.getEventData(vm, to.params.eventId);

      return undefined;
    });
  },
  async beforeRouteUpdate(to, from, next) {
    this.status = null;

    if (from.params.eventId !== to.params.eventId) {
      await this.getEventData(null, to.params.eventId);

      next();
    }
  },
  methods: {
    async getEventData(vm, eventId) {
      const self = vm || this;

      self.status = 'loading';

      const currentEventId = Number(eventId);

      const event = await self.getEventById(currentEventId);

      if (!event) {
        self.status = 'error';
      } else {
        self.status = null;
      }
    },
    handleContactShow() {
      // this.status = 'recaptcha-checking';
      // this.executeRecaptcha().then(
      //   () => {
      this.status = 'contact-shown';
      //   },
      //   () => {
      //     this.status = 'recaptcha-error';
      //   },
      // );
    },
    handleCloseModal() {
      this.$router.push({ name: 'Map' });
    },
    async handleRemoveEvent() {
      const confirmed = window.confirm(
        `Вы уверены, что хотите удалить событие "${this.event.name}"?`,
      );

      if (!confirmed) return;

      try {
        await this.removeEventById(this.event.id);
        this.$router.replace('/map');
      } catch (e) {
        if (e.message === 'UNAUTHORIZED') {
          this.$router.replace('/login');
        }

        console.log(e);
      }
    },
    ...mapActions(['getEventById', 'removeEventById']),
  },
  mixins: [RecaptchaMixin],
  components: {
    Icon,
    Button,
    Spinner,
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  z-index: 1000;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  width: calc(100% - 2rem);
  max-width: 25.5rem;
  height: auto;
  min-height: 19rem;
  padding: 1.5rem 1.5rem 2rem;

  background: var(--color-white);
  border: 0;
  border-radius: 1.5rem;

  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25);

  @media (min-width: 768px) {
    width: calc(100% - 5rem);
  }

  @media (min-width: 1024px) {
    max-width: 28rem;
  }
}

.close {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;

  padding: 0.5rem;

  color: var(--color-gray-10);
  font-size: 2.3rem;

  border: 0;
  background: none;
  box-shadow: none;

  cursor: pointer;
}

.remove {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;

  padding: 0.5rem;

  color: var(--color-danger-20);
  font-size: 2rem;

  border: 0;
  background: none;
  box-shadow: none;

  cursor: pointer;
}

.content {
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  &--error {
    align-items: center;
  }
}

.content__picture {
  width: 10rem;
}

.heading {
  grid-column: span 2;

  margin: 0 auto 0.5rem;
  max-width: 80%;

  color: var(--color-primary-50);

  font-size: var(--fs-h3);
  line-height: var(--lh-h3);
  font-weight: 700;
  text-align: center;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &--error {
    margin-top: 2rem;
    color: var(--color-text);
  }
}

.paragraph {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.row {
  margin-top: 1rem;

  display: flex;
  flex: 0 0 auto;

  &--placeholder {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 7rem 1fr;
  }

  &:last-child {
    padding-top: 1rem;
    margin-top: auto;
  }

  @media (min-width: 1024px) {
    margin-top: 1.25rem;

    &--placeholder {
      grid-template-columns: 8rem 1fr;
    }
  }
}

.label {
  margin-right: 1rem;
  flex: 0 0 7rem;
  color: var(--color-gray-50);
  text-align: right;

  @media (min-width: 1024px) {
    flex: 0 0 8rem;
  }
}

.value {
  flex: 1 0 auto;
  width: 0;
}

.value--accent {
  color: var(--color-primary-50);
}

.comment {
  width: 100%;

  max-height: 8em;
  padding: 1rem;

  color: var(--color-primary-50);
  border: 1px solid var(--color-gray-10);
  border-radius: 0.5rem;

  text-align: center;
  overflow-y: auto;
}

.button {
  margin-top: 0.5rem;
  width: 100%;
}

.contact {
  display: flex;
  justify-content: center;

  width: 100%;
  height: 3.5rem;
  padding: 1rem;

  font-size: var(--fs-h4);
  line-height: var(--lh-text);

  color: var(--color-primary-50);
  text-align: center;

  overflow-x: hidden;
  overflow-y: auto;
  word-break: break-all;

  &::v-deep a {
    margin: 0 0.5em;
    color: var(--color-primary-50);
  }
}

.contact--error {
  color: var(--color-danger-20);
}

.placeholder {
  margin: 0;
  width: 100%;
  height: calc(var(--fs-text) * 1.42);
  border-radius: 0.5rem;
  background: linear-gradient(90deg, #55dd8b 0%, #aff9b5 51.56%, #55dd8b 98.44%);
  background-size: 20rem;
  animation: placeholder linear 1.5s infinite;

  opacity: 0.2;
}

.placeholder--heading {
  width: 60%;
  height: calc(var(--fs-h3) * 1.1);
  align-self: center;

  opacity: 0.4;
}

.placeholder--xl {
  grid-column: span 2;
  height: 4rem;

  @media (min-width: 1024px) {
    height: 5rem;
  }
}

.placeholder--lg {
  width: 80%;
}

.placeholder--md {
  width: 60%;
}

.placeholder--sm {
  width: 40%;
}
</style>
