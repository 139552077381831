import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import EventsFilter from '../views/Find.vue';
import EventCreate from '../views/Create.vue';
import EventDetails from '../views/Event.vue';
import EventsGroup from '../views/Group.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/map',
    name: 'Map',
    component: () => import('../views/Map.vue'),
    alias: '/onboarding',
    children: [
      {
        name: 'Find',
        path: 'find',
        component: EventsFilter,
      },
      {
        name: 'New',
        path: 'new',
        component: EventCreate,
      },
      {
        name: 'Events',
        path: 'group',
        component: EventsGroup,
      },
      {
        name: 'Event',
        path: ':eventId',
        component: EventDetails,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy Policy',
    component: () => import('../views/Privacy.vue'),
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: () => import('../views/Maintenance.vue'),
  },
  {
    path: '/404',
    alias: '*',
    name: '404',
    component: () => import('../views/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
