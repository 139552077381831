import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import WebFont from 'webfontloader';
import VTooltip from 'v-tooltip';
import VueMask from 'v-mask';
import VueMeta from 'vue-meta';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

import App from './App.vue';
import router from './router';
import store from './store';
import api from './api';

import intersectionObserver from './plugins/intersectionObserver';

import '@/assets/styles/global.scss';
import 'v-tooltip/dist/v-tooltip.css';
import config from './config';

Sentry.init({
  Vue,
  dsn: 'https://db8dc83c306b460b9f74452d78755219@o935569.ingest.sentry.io/5885380',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['radiusfriend.com', /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

WebFont.load({ custom: { families: ['Jost'] } });

Vue.config.productionTip = false;

Vue.use(VueCompositionAPI);
Vue.use(config);
Vue.use(api);
Vue.use(intersectionObserver);
Vue.use(VueMask);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
Vue.use(VTooltip, {
  themes: {
    primary: {
      placement: 'bottom-start',
      triggers: [],
      delay: {
        show: 400,
      },
      $extend: 'tooltip',
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
