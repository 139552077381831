<template>
  <ModalForm
    ref="modal"
    @submit="handleSubmit"
    @close="handleCloseModal"
  >
    <!-- Dummy Input used for preventing autofocus on Select control -->
    <input
      ref="dummyInput"
      class="visually-hidden"
    />
    <FormRow>
      <Select
        name="type"
        ref="type"
        :value="filter.type"
        :options="eventTypes"
        placeholder="Начните вводить искомое событие"
        :fetchOptions="handleOptionsLoad"
        @focus="handleOptionsLoad"
        @blur="$v.filter.type.$touch"
        @option:selecting="handleUpdate('type', $event)"
        @input="handleUpdate('type', $event)"
        :error="$v.filter.type.$error"
      >
        Тип занятия

        <template #no-options="{ search }">
          <router-link
            class="vs__no-options-link"
            :to="`/map/new?type=${search}`"
          >
            Событие
            <span
              class="nowrap"
            >"<span class="vs__no-options-label">{{ search }}</span>"</span>
            не найдено.<br />
            Вы можете <strong>создать</strong> свое событие.
          </router-link>
        </template>
      </Select>
    </FormRow>
    <FormRow>
      <Datepicker
        name="date"
        :value="filter.date"
        @change="handleUpdate('date', $event)"
      >Дата</Datepicker>
    </FormRow>
    <!-- <FormRow>
      <Timepicker
        name="time"
        :value="filter.time"
        @change="handleUpdate('time', $event)"
      >Время</Timepicker>
    </FormRow> -->
    <FormRow>
      <Input
        name="ownerAge"
        type="number"
        :value="filter.ownerAge"
        @input="handleUpdate('ownerAge', $event)"
        @blur="$v.filter.ownerAge.$touch"
        :min="0"
        :max="99"
        :error="$v.filter.ownerAge.$error"
      >Ваш возраст</Input>
    </FormRow>
    <FormRow>
      <Range
        name="targetAge"
        :value="filter.targetAge"
        @input="handleUpdate('targetAge', $event)"
        :min="0"
        :max="99"
      >Возраст участников</Range>
    </FormRow>
    <FormRow>
      <Range
        name="participantCount"
        :value="filter.participantCount"
        @input="handleUpdate('participantCount', $event)"
        :min="1"
        :max="99"
      >Кол-во участников</Range>
    </FormRow>
    <FormRow class="checkbox-field">
      <SilenceCheckbox
        name="silence"
        :value="filter.silence"
        @change="handleUpdate('silence', $event)"
      />
    </FormRow>
    <FormRow
      class="checkbox-field"
      condensed
    >
      <Checkbox
        name="free"
        :value="filter.free"
        @change="handleUpdate('free', $event)"
      >
        Бесплатное
      </Checkbox>
    </FormRow>
    <FormRow>
      <Button
        class="search__reset"
        variant="ghost"
        type="reset"
        @click.prevent="handleReset"
      >Сбросить фильтр</Button>

      <Button
        type="submit"
        class="search__submit"
        variant="secondary"
      >{{
        filter.dirty ? 'Найти' : 'Закрыть'
      }}</Button>
    </FormRow>
  </ModalForm>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { maxLength, minValue, maxValue } from '@vuelidate/validators';
import { mapActions, mapMutations, mapState } from 'vuex';
import debounce from 'lodash/debounce';

import Input from '@/components/Input.vue';
import Datepicker from '@/components/Datepicker.vue';
// import Timepicker from '@/components/Timepicker.vue';
import Range from '@/components/Range.vue';
import Button from '@/components/Button.vue';
import Checkbox from '@/components/Checkbox.vue';
import SilenceCheckbox from '@/components/SilenceCheckbox.vue';
// import AddressInput from '@/components/AddressInput.vue';
import Select from '@/components/Select.vue';
import ModalForm from '@/components/ModalForm.vue';
import FormRow from '@/components/FormRow.vue';

export default {
  name: 'Create',
  metaInfo: {
    title: 'Поиск событий',
    meta: [
      {
        vmid: 'og:title',
        property: 'og:title',
        content: 'Поиск событий',
      },
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: 'Поиск событий',
      },
    ],
  },
  setup() {
    return {
      $v: useVuelidate(),
    };
  },
  data() {
    return {
      silenceTooltipConfig: {
        content:
          'Все мы знаем, как бывает неловко в момент тишины с незнакомым человеком. Поэтому мы решили добавить данную опцию. Теперь вам не нужно волноваться о том, что вы не найдете сразу слов при общении, ведь ваш собеседник и вы будете не против неловкой тишины =)',
        placement: 'bottom-end',
        theme: 'primary',
        class: 'silence-tooltip',
      },
    };
  },
  computed: {
    stage: {
      set(newStage) {
        this.$store.commit('updateStage', newStage);
      },
      get() {
        return this.$store.state.app.stage;
      },
    },
    ...mapState({
      filter: (state) => state.events.filter,
      eventTypes: (state) => state.events.types,
    }),
  },
  watch: {
    stage() {
      switch (this.stage) {
        case 'search:form':
          this.$refs.modal.show();
          break;

        default:
          this.$refs.modal.close();
      }
    },
  },
  validations() {
    return {
      filter: {
        type: {
          label: {
            maxLength: maxLength(100),
          },
        },
        ownerAge: {
          minValue: minValue(0),
          maxValue: maxValue(100),
        },
      },
    };
  },
  mounted() {
    this.updateStage('search:form');
    this.$refs.modal.show();
    this.$refs.dummyInput.blur();
  },
  beforeRouteLeave(to, from, next) {
    this.updateStage('init');
    this.$refs.modal.close();
    next();
  },
  methods: {
    handleCloseModal() {
      this.updateStage('init');
      this.$router.push({ name: 'Map' });
    },
    handleUpdate(name, value) {
      this.updateFilter({
        [name]: value,
      });
    },
    handleOptionsLoad: debounce(function (query, setLoading) {
      let spinnerTimeout = 0;
      setLoading = setLoading || this.$refs.type.toggleLoading;

      spinnerTimeout = setTimeout(() => {
        setLoading(true);
      }, 1000);

      this.fetchEventTypes(query).then(() => {
        clearTimeout(spinnerTimeout);
        setLoading(false);
      });
    }, 200),
    async validateForm() {
      await this.$v.$validate();
    },
    handleSubmit() {
      this.$v.$touch();

      if (this.$v.$error) return;

      if (!this.filter.dirty) {
        this.$router.push({ name: 'Map' });
        return;
      }

      this.updateFilter({
        applied: true,
      });

      this.fetchEvents();

      this.$router.push({ name: 'Map' });
    },
    handleReset() {
      this.$v.$reset();
      this.resetFilter();
      this.fetchEvents();
    },
    ...mapMutations(['updateFilter', 'resetFilter', 'updateStage']),
    ...mapActions(['fetchEventTypes', 'fetchEvents']),
  },
  components: {
    Input,
    Datepicker,
    // Timepicker,
    Range,
    Select,
    Checkbox,
    SilenceCheckbox,
    Button,
    ModalForm,
    FormRow,
    // AddressInput,
  },
};
</script>

<style lang="scss" scoped>
.search__reset {
  grid-row: 2 / 3;
  flex: 0 0 100%;
  order: 2;
}

.search__submit {
  flex: 0 0 100%;
}

.checkbox-field {
  padding-left: 4.25rem;
}

@media (min-width: 768px) {
  .search__reset {
    grid-row: 1 / 2;
    margin-top: 0;
    flex: 0 0 auto;
    order: -1;
  }

  .search__submit {
    flex: 1 0 auto;
  }

  .checkbox-field {
    padding-left: 5.25rem;
  }
}
</style>
