<template>
  <div
    :class="[
      'control',
      hasLabel && 'control--with-label',
      hasNoOptionsSlot && 'control--with-no-options',
    ]"
  >
    <label
      v-if="hasLabel"
      :for="id"
      :class="['label', required && 'label--required', error && 'label--error']"
    >
      <slot></slot>
    </label>

    <VSelect
      ref="input"
      :inputId="id"
      :class="['input', !value.value && 'input--empty', error && 'input--error']"
      :value="value"
      :placeholder="placeholder"
      :reduce="reduce"
      :options="options"
      :maxlength="maxlength"
      :disabled="disabled"
      :clearable="false"
      :taggable="combobox"
      :autofocus="false"
      :create-option="(label) => ({ id: null, label, value: label })"
      :getOptionLabel="getOptionLabel"
      @search="fetchOptions"
      @search:focus="$emit('focus', $event)"
      @search:blur="$emit('blur', $event)"
      @option:selecting="$emit('option:selecting', $event)"
      @input="$emit('input', $event)"
    >
      <template #option="{ id, value }">
        <div class="option">
          <span class="option__value">
            {{ value }}
          </span>
          <span
            v-if="!id"
            class="option__caption"
          >Добавить</span>
        </div>
      </template>

      <template #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <Icon icon="arrow-down" />
        </span>
      </template>

      <template
        v-if="hasNoOptionsSlot"
        #no-options="{ search, searching }"
      >
        <slot
          name="no-options"
          :search="search"
          :searching="searching"
        ></slot>
      </template>
    </VSelect>
  </div>
</template>

<script>
import format from 'date-fns/format';
import VSelect from 'vue-select';
import uniqueId from 'lodash/uniqueId';

import Icon from '@/components/Icon.vue';

import 'vue-select/dist/vue-select.css';

export default {
  props: {
    name: String,
    maxlength: Number,
    value: Object,
    options: Array,
    reduce: Function,
    fetchOptions: Function,
    placeholder: String,
    combobox: Boolean,
    required: Boolean,
    error: [String, Boolean],
    disabled: Boolean,
  },
  data() {
    return {
      id: uniqueId(),
    };
  },
  computed: {
    hasNoOptionsSlot() {
      return this.$scopedSlots['no-options'];
    },
    minValue() {
      return this.type === 'date' ? format(new Date(), 'yyyy-MM-dd') : this.min;
    },
    maxValue() {
      return this.type === 'date' ? '2099-12-31' : this.max;
    },
    hasLabel() {
      return !!this.$slots.default;
    },
  },
  components: {
    VSelect,
    Icon,
  },
  mounted() {
    // Hack for v-select placeholder. Placeholder doesn't appear without this.
    this.$refs.input.searchEl.setAttribute('placeholder', this.placeholder);
  },
  updated() {
    this.$refs.input.searchEl.setAttribute('placeholder', this.placeholder);
  },
  methods: {
    handleCreateOption(option) {
      return {
        label: option,
        value: option,
      };
    },
    getOptionLabel(option) {
      if (typeof option === 'object') {
        if (!option.label) {
          return option.value;
        }
        return option.label;
      }
      return option;
    },
    focus() {
      this.$refs.input.searchEl.focus();
    },
    blur() {
      this.$refs.input.searchEl.blur();
    },
    toggleLoading(loading) {
      this.$refs.input.toggleLoading(loading);
    },
  },
};
</script>

<style lang="scss" scoped>
.control {
  width: 100%;

  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  align-items: center;

  &--with-label {
    grid-template-columns: 5.5rem 1fr;
  }

  @media (min-width: 1024px) {
    &--with-label {
      grid-template-columns: 6.5rem 1fr;
    }
  }
}

.label {
  position: relative;
  top: 1rem;
  color: var(--color-primary-50);
  line-height: 1.15;
  align-self: start;
  text-align: right;

  transform: translateY(-50%);

  &--required:after {
    content: '*';
    display: inline-block;
    width: 0;
    color: var(--color-primary-50);
  }

  &--error {
    color: var(--color-danger-20);
  }

  @media (min-width: 1024px) {
    top: 1.25rem;
  }
}
</style>

<style lang="scss">
.vs__dropdown-toggle {
  position: relative;
  z-index: 1001;

  width: 100%;
  padding: 0.375rem 0.5rem 0.375rem 0.5rem;

  background: var(--color-white);
  border: 1px solid var(--color-gray-10);
  border-radius: 0.5rem;

  .input--error & {
    border-color: var(--color-danger-20);
  }

  @media (min-width: 1024px) {
    height: 2.5rem;
    padding: 0.5rem;
  }
}

.vs--open .vs__dropdown-toggle {
  border-radius: 0.5rem;
  border-bottom-color: var(--color-gray-10);
}

.vs__search {
  &,
  &:focus {
    margin: 0;
    padding-left: 0;

    width: 100%;
    flex: 0 1 auto;

    color: var(--color-text);
    font-size: var(--fs-text);
    line-height: 1.33;

    border: 0;
  }

  &::placeholder {
    color: var(--color-gray-20);
  }
}

.vs__open-indicator {
  color: var(--color-gray-20);
  font-size: 1rem;
}

.vs__actions {
  padding: 0;
}

.vs__no-options {
  display: none;

  .input--combobox & {
    display: block;
    padding: 1rem 0.5rem 0.5rem;
    text-align: center;
  }

  .control--with-no-options & {
    display: block;
  }

  &-label {
    display: inline-block;
    max-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
  }

  &-link {
    display: block;
    padding: 1rem;

    color: var(--color-text);

    text-align: center;
    text-decoration: none;
  }

  strong {
    font-weight: 400;
    color: var(--color-primary-50);
    text-decoration: underline;
  }
}

.vs__dropdown-menu {
  margin-top: -0.7rem;
  padding: 0.5rem 0 0;
  border-radius: 0.5rem;
  box-shadow: none;
  overflow: hidden;
}

.vs__dropdown-option {
  padding: 0.375rem 0.5rem;

  &:hover {
    background-color: rgba(#000, 0.1);
    color: var(--color-text);
  }
}

.vs__dropdown-option--highlight {
  color: var(--color-text);
  background-color: var(--color-primary-10);
}

.vs__selected {
  flex: 1 0 auto;
  margin: 0;
  padding: 0;

  border: 0;
  color: var(--color-text);
  line-height: 1.33;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input {
  width: 100%;
}

.input:not(.input--empty) input::placeholder {
  color: transparent;
}

.vs__selected-options {
  display: flex;
  flex-wrap: nowrap;
  padding: 0;
  overflow: hidden;
}

.vs--single.vs--searching:not(.vs--open):not(.vs--loading) .vs__search {
  opacity: 1;
}

.vs__spinner,
.vs__spinner:after {
  width: 1rem;
  height: 1rem;
}

.vs__spinner {
  border: 2px solid var(--color-primary-20);
  border-left-color: var(--color-primary-50);
}

.option {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.option__value {
  flex: 0 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
}

.option__caption {
  flex: 0 0 auto;
  font-size: 0.8em;
  color: var(--color-gray-50);
}
</style>
