<template>
  <footer class="footer">
    <Container>
      <div class="footer__row">
        <h2 class="visually-hidden">Скачайте наше приложение:</h2>
        <Downloads />
      </div>

      <div class="footer__row">
        <h2 class="visually-hidden">Как с нами связаться:</h2>
        <Socials />
      </div>
    </Container>
  </footer>
</template>

<script>
import Container from '@/components/Container.vue';
import Socials from '@/components/Socials.vue';
import Downloads from '@/components/Downloads.vue';

export default {
  name: 'Footer',
  components: {
    Container,
    Socials,
    Downloads,
  },
};
</script>

<style lang="scss" scoped>
.footer {
  flex: 0 0 auto;
  padding-bottom: 1.5rem;
}

.footer__row + .footer__row {
  margin-top: 1.5rem;
}
</style>
