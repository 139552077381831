<template>
  <a
    v-if="href"
    :class="['button', variant, small && 'small', className]"
    :href="href"
    :target="target"
    v-on="$listeners"
  >
    <slot />
  </a>
  <router-link
    v-else-if="to"
    :class="['button', variant, small && 'small', className]"
    :to="to"
    v-on="$listeners"
  >
    <slot />
  </router-link>
  <button
    v-else
    :class="['button', variant, small && 'small', className]"
    :type="type"
    :disabled="disabled"
    v-on="$listeners"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    className: String,
    variant: {
      validator(value) {
        return ['primary', 'secondary', 'ghost'].indexOf(value) !== -1;
      },
      default: 'primary',
    },
    type: {
      validator(value) {
        return ['button', 'reset', 'submit'].indexOf(value) !== -1;
      },
      default: 'button',
    },
    href: String,
    to: String,
    target: {
      validator(value) {
        return [undefined, '_self', '_blank'].indexOf(value) !== -1;
      },
      default: undefined,
    },
    small: Boolean,
    disabled: Boolean,
    onClick: Function,
  },
};
</script>

<style lang="scss" scoped>
.button {
  display: grid;
  grid-gap: 0.5rem;
  grid-auto-flow: column;
  align-items: center;
  font-family: var(--ff-base);
  padding: 0;
  background: 0;
  border: 0;
  box-shadow: none;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
}

.primary {
  min-width: 11rem;
  height: 3rem;
  padding: 0 2rem;

  font-size: var(--fs-button);
  line-height: 3rem;
  color: var(--color-white);
  background-color: var(--color-primary-50);

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 1.5rem;

  transition: background-color 0.15s linear;

  &:disabled {
    cursor: default;
    background-color: var(--color-gray-10);
  }
}

.secondary {
  min-width: 11rem;
  height: 2.75rem;
  padding: 0 2rem;

  font-size: var(--fs-text);
  line-height: 2.75rem;
  color: var(--color-white);
  background-color: var(--color-primary-50);

  box-shadow: none;
  border-radius: 0.5rem;

  transition: background-color 0.15s linear;

  &:disabled {
    cursor: default;
    background-color: var(--color-gray-10);
  }
}

.ghost {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;

  font-size: var(--fs-text);
  line-height: inherit;
  text-decoration: underline;
  color: var(--color-primary-50);
}

.small {
  height: 2rem;
  line-height: 2rem;
  font-size: var(--fs-text);
}
</style>
